
.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width:16.666667%;
    position: fixed;
    background-color: #e8e5e5;
    padding:0.5em 0;

    
}

.navbar img {
    max-width: 50px;
    /* padding-bottom:5px; */
    
    
    
}
.nav-list-con{
    text-align: center;
    transform:translateY(1em);
}

.copyRightCon {
    position: relative;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 100%;
}



@media screen and (max-width: 870px) {
    .nav-bar-con {
        align-items: center;
        width: 100%;
        left: 23px;
        top: 25px;
       
        background-color: inherit;
    }
    .nav-list-con{
        text-align: center;
        transform:translateY(0em);
        display: flex;
        align-items: center;
        color: #47524b;
    
    }
   
    .navbar {
        flex-direction: row;
        padding:1em;
        width:100%;
        background-color: #d6d3d3;
        /* transform: translateY(45px); */
        height:auto;
        padding:0px;
      
    }
  
   
}

