.mage-view-box-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.img-con{
    padding:2em;
    
}
.inside-image {
 margin:auto;
 max-width: 100%;
 max-height: 85vh;

 position: relative;
}
.close-btn{
   
    color: rgb(195, 146, 46);
    cursor: pointer;
}
.prev-btn{
    
    color:rgb(195, 146, 46);
    cursor: pointer;
}
.next-btn{
   
    color:rgb(195, 146, 46);
    cursor: pointer;
}
.home-page-con{
display: flex;


flex-direction: column;
align-items: center;
justify-content: space-around;
}
.slide .CarouselItem img{
    max-width:100%;
    max-height:90vh;
}


.contactMe-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-evenly;
}
.d-row{
    flex-direction: row;
}
.contactMe-container p{
    
    padding-right: 5px;
}
.aboutMe-page-con{
    width: auto;
    height: 100%;
    padding:1em;
    display: flex;
    align-items: center;
    line-height: 30px;
    text-align: initial;
}
@media screen and (max-width: 768px) {
    .img-con{
        padding:.4em;
        
    }
    .slide .CarouselItem img{
        max-width:100%;
        max-height:80vh;
    }
    .slide{
       display: flex;
       align-items: center;
       height:80vh;
    }
    .inside-image {
       width:100%;
       height:auto;
       }
}