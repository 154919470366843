
.nav-list-item-text  a:focus-within{
color: #c3922e;

}

.nav-list-item-text span{
    font-family: 'Abel', sans-serif;
    
    }

.nav-Albumslist-item-text a:focus-within{
        color:  #c3922e
    }
   
    .nav-Albumslist-item-text span{
        color: inherit
    }

   



.image {
    width: 100%;
    cursor: pointer;
   
}




.stack-grid-image img {
    transition: all 0.3s ease-in-out;
    
    
}
.stack-grid-image img:hover {
    opacity: 0.8;
    transform:scale(1.05)
   
    
    
}
