*{
    margin:0;
    padding:0;
    box-sizing: border-box ;
}
body {
   
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:#d6d3d3; 
    color: #47524b; 
    width: 100%;
    height: 100%;
    
 
   
}
#root{
    font-family: 'Abel', sans-serif;
    width: 100%;
    height: 100%;
   
    
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a{
text-decoration: none;
color:inherit}
header{
    height: 100vh;
}