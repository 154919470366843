*{
    margin:0;
    padding:0;
    box-sizing: border-box ;
}
body {
   
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:#d6d3d3; 
    color: #47524b; 
    width: 100%;
    height: 100%;
    
 
   
}
#root{
    font-family: 'Abel', sans-serif;
    width: 100%;
    height: 100%;
   
    
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
a{
text-decoration: none;
color:inherit}
header{
    height: 100vh;
}

.nav-list-item-text  a:focus-within{
color: #c3922e;

}

.nav-list-item-text span{
    font-family: 'Abel', sans-serif;
    
    }

.nav-Albumslist-item-text a:focus-within{
        color:  #c3922e
    }
   
    .nav-Albumslist-item-text span{
        color: inherit
    }

   



.image {
    width: 100%;
    cursor: pointer;
   
}




.stack-grid-image img {
    transition: all 0.3s ease-in-out;
    
    
}
.stack-grid-image img:hover {
    opacity: 0.8;
    -webkit-transform:scale(1.05);
            transform:scale(1.05)
   
    
    
}


.navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width:16.666667%;
    position: fixed;
    background-color: #e8e5e5;
    padding:0.5em 0;

    
}

.navbar img {
    max-width: 50px;
    /* padding-bottom:5px; */
    
    
    
}
.nav-list-con{
    text-align: center;
    -webkit-transform:translateY(1em);
            transform:translateY(1em);
}

.copyRightCon {
    position: relative;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 100%;
}



@media screen and (max-width: 870px) {
    .nav-bar-con {
        align-items: center;
        width: 100%;
        left: 23px;
        top: 25px;
       
        background-color: inherit;
    }
    .nav-list-con{
        text-align: center;
        -webkit-transform:translateY(0em);
                transform:translateY(0em);
        display: flex;
        align-items: center;
        color: #47524b;
    
    }
   
    .navbar {
        flex-direction: row;
        padding:1em;
        width:100%;
        background-color: #d6d3d3;
        /* transform: translateY(45px); */
        height:auto;
        padding:0px;
      
    }
  
   
}


.mage-view-box-con{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}
.img-con{
    padding:2em;
    
}
.inside-image {
 margin:auto;
 max-width: 100%;
 max-height: 85vh;

 position: relative;
}
.close-btn{
   
    color: rgb(195, 146, 46);
    cursor: pointer;
}
.prev-btn{
    
    color:rgb(195, 146, 46);
    cursor: pointer;
}
.next-btn{
   
    color:rgb(195, 146, 46);
    cursor: pointer;
}
.home-page-con{
display: flex;


flex-direction: column;
align-items: center;
justify-content: space-around;
}
.slide .CarouselItem img{
    max-width:100%;
    max-height:90vh;
}


.contactMe-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content:space-evenly;
}
.d-row{
    flex-direction: row;
}
.contactMe-container p{
    
    padding-right: 5px;
}
.aboutMe-page-con{
    width: auto;
    height: 100%;
    padding:1em;
    display: flex;
    align-items: center;
    line-height: 30px;
    text-align: initial;
}
@media screen and (max-width: 768px) {
    .img-con{
        padding:.4em;
        
    }
    .slide .CarouselItem img{
        max-width:100%;
        max-height:80vh;
    }
    .slide{
       display: flex;
       align-items: center;
       height:80vh;
    }
    .inside-image {
       width:100%;
       height:auto;
       }
}
